@use "../../../styles/variables";

.main {
  background-color: variables.$color-gray-light;
}

.about_section {
  // padding-top: 120px;
  display: grid;
  place-items: center;
}

.features_section {
  display: grid;
  place-items: center;
  padding: 120px 0;
}
