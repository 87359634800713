@use "../../styles/variables";

.wrapper {
  background-color: variables.$color-gray-light;
}

.about_section {
  padding-top: 60px;
}

.features_section {
  padding: 120px 0;

  @media screen and (max-width: 590px) {
    padding-top: 60px;
  }
}
