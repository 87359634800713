@use "../../styles/variables";

.features_block {
  width: 100%;
  max-width: 100%;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 136px;
    align-items: center;

    @media screen and (max-width: 1028px) {
      gap: 80px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      gap: 40px;
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 460px;
}

.content {
  &__title {
    color: variables.$color-gray-dark;
    font-size: 48px;
    line-height: normal;
    letter-spacing: 1.92px;
    text-transform: uppercase;
  }

  &__desc {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      color: variables.$color-gray-dark;
      font-size: 20px;
      //   line-height: normal;
      letter-spacing: 0.8px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
}
