@mixin buttonReset {
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}
