@use "../../styles/variables";

.about_block {
  background-color: variables.$color-gray-light;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 136px;

    @media screen and (max-width: 1028px) {
      gap: 80px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 40px;
    }

    @media screen and (max-width: 590px) {
      flex-direction: column-reverse;
      gap: 40px;
    }
  }
}

.content {
  &__title {
    font-size: 48px;
    line-height: normal;
    letter-spacing: 1.92px;
    color: variables.$color-gray-dark;
    text-transform: uppercase;
  }

  &__desc {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      font-weight: 400;
      font-size: 20px;
      color: variables.$color-gray-dark;
    }
  }
}

.image_wrapper {
  width: 100%;
  &__container {
    position: relative;
    height: 442px;

    @media screen and (max-width: 500px) {
      height: 300px;
    }
  }
}
