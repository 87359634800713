@use "../../styles/variables";
@use "../../styles/mixins";

.wrapper {
  width: 100%;
  position: relative;
}

.quick_form {
  padding: 32px 24px;
  background: variables.$color-white;
  border-radius: 16px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  // backdrop-filter: blur(14px);
  margin-top: -160px;
  z-index: 50;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    &__button {
      @include mixins.buttonReset;
      color: variables.$color-gray-dark;
      text-transform: capitalize;
      border-bottom: 1px solid transparent;
      padding-bottom: 8px;
      transition: text-shadow 0.3s;

      &:hover,
      &.active {
        text-shadow: 0 0 0.65px variables.$color-gray-dark,
          0 0 0.65px variables.$color-gray-dark;
        border-bottom: 1px solid variables.$color-gray-dark;
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: 0 -16px 0;
    border-radius: 0;
  }
}

// Form Fields Styling

.form_body {
  margin-top: 24px;
  margin-bottom: 24px;

  hr {
    margin-top: 24px;
    margin-bottom: 24px;
    border: 0;
    border-bottom: 1px solid #efefef;
    width: 100%;
    // border-color: #fafafa;
  }

  .row {
    position: relative;
    display: grid;
    gap: 16px;

    &:first-of-type {
      .closeMark {
        display: none;
      }
    }

    .closeMark {
      font-size: 24px;
      position: absolute;
      top: -36px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    @media screen and (min-width: 550px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    @media screen and (min-width: 900px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }

    @media screen and (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      > div {
        width: 100%;

        &:last-child {
          flex-shrink: 2;
        }
      }
    }
  }
}

.form_input_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input_group {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: variables.$color-white;
  height: 56px;
  padding: 16px 16px;

  .icon {
    font-size: 20px;
  }

  input {
    flex-grow: 1;
    border: 0;
    outline: 0;
    width: 100%;
    color: variables.$color-gray-dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &::placeholder {
      color: variables.$color-gray-dark;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    :focus {
      outline: 0;
    }
  }
}

.custom_input {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 120px;
  input {
    text-align: center;
    width: 30px;
  }
  button {
    @include mixins.buttonReset;
    width: 24px;
    height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 390px) {
    flex-direction: column;
  }
}

.button {
  @include mixins.buttonReset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: variables.$color-primary;
  color: variables.$color-white;
  border-radius: 64px;
  padding: 14px 28px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 390px) {
    width: 100%;

    &:hover {
      transform: scale(1.02);
    }
  }
}
